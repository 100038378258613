<template>
<div >>
    <div class="container-fluid px-0 mx-0" style="background-color: #2E2D2D">
           <img src="../assets/images/ruko high res 1.png" class="img-fluid" width="100%" alt="">
    </div>
    <div class="container-fluid py-5 px-0 mx-0" style="background-color: #2E2D2D">
           <div class="container text-center">
             <div class="row">
               <div class="col-12 fasilitas mb-2">
                 <h1>FASILITAS</h1>
               </div>
             </div>
             <div class="row">
               <div class="col-2"></div>
               <div class="col-8 isiFasi">
                 <span>Salah satu pertimbangan para penghuni perumahan adalah faktor fasilitas di dalam perumahan seperti pusat bisnis, sarana ibadah dan pasar bersih yang mudah dijangkau dan aman. Selain itu, ESTUSAE ALAM INDAH juga dilengkapi dengan fasilitas mewah lainnya seperti:</span>
               </div>
               <div class="col-2"></div>
             </div>
           </div>
    </div>
    <div class="container-fluid pb-4 mb-4 px-0 mx-0" style="background-color: #FFFFFF">
           <img src="../assets/images/Group 91 (1).png" class="img-fluid" width="100%" alt="">
    </div>  
    <div class="container-fluid pb-4 mb-4 px-0 mx-0" style="background-color: #FFFFFF">
           <img src="../assets/images/Group 92.png" class="img-fluid" width="100%" alt="">
    </div>
    <div class="container-fluid pb-4 mb-4 px-0 mx-0" style="background-color: #FFFFFF">
           <img src="../assets/images/Group 93.png" class="img-fluid" width="100%" alt="">
    </div>
    <div class="container-fluid pb-4 mb-4 px-0 mx-0" style="background-color: #FFFFFF">
           <img src="../assets/images/Group 94.png" class="img-fluid" width="100%" alt="">
    </div>
    <div class="container-fluid py-0 my-0 px-0 mx-0" style="background-color: #FFFFFF">
           <img src="../assets/images/Group 95.png" class="img-fluid" width="100%" alt="">
    </div>
</div>
</template>
<script>
export default {
  components: { },
  name: 'fasilitas',
    mounted () {
    window.scrollTo(0, 0)
  },

}
</script>
<style scoped>
@font-face {
 
    src: url(../font/F37Bolton-Light.woff2.ttf);
    font-family: F37 Bolton;
}

.fasilitas{
  font-family: Manuale;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.35em;
  color: #FFFFFF;
}

.isiFasi{
  font-family: F37 Bolton;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.09em;
  color: #FFFFFF;

}
</style>